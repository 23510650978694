<template>
  <v-row no-gutters>
    <v-col lg="4" md="4" cols="12" class="pa-2">
      <v-card>
        <v-card-title primary-title>Account</v-card-title>
        <v-card-text class="py-1">
          <v-form ref="accountForm" v-model="validAccount" v-on:submit.prevent lazy-validation>
            <v-text-field
              v-model="account.userAccountName"
              placeholder="Username"
              :rules="accountRule"
              label="Username"
              name="account"
              required
            ></v-text-field>
            <v-text-field
              v-model="account.shortName"
              placeholder="Profile Name"
              :rules="accountShortRule"
              label="Profile Name"
              name="accountShortName"
              required
            ></v-text-field>
            <v-text-field
              v-model="account.password"
              placeholder="Password"
              :rules="accountPasswordRule"
              label="Password"
              name="accountPassword"
              required
            ></v-text-field>
            <v-select v-model="account.accountType" :items="accountType" label="Pessenger Type"></v-select>
          <v-combobox
            v-model="account.branch"
            :items="branchList"
            label="Branch"
            item-text="description"
            return-object
            required
            dense
          ></v-combobox>
          </v-form>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pt-1">
          <v-spacer></v-spacer>
          <v-btn
            v-if="account.status=='ADD'"
            :disabled="!validAccount"
            @click="addAccount"
            color="success"
            class="ma-1"
            depressed
          >
            <v-icon>add</v-icon>
            <span class="ml-2">Add</span>
          </v-btn>
          <v-btn
            v-if="account.status=='UPDATE'"
            :disabled="!validAccount"
            color="primary"
            class="ma-1"
            @click="updateAccount"
            depressed
          >
            <v-icon>update</v-icon>
            <span class="ml-2">Update</span>
          </v-btn>
          <v-btn
            v-if="account.status=='UPDATE'"
            color="error"
            class="ma-1"
            depressed
            outlined
            @click="cancelAccount"
          >
            <v-icon>close</v-icon>
            <span class="ml-2">Cancel</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col lg="8" md="8" cols="12" class="pa-2">
      <v-card>
        <v-card-title>
          Account List
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="accountHeader"
            :items="accountList"
            :items-per-page="10"
            class="elevation-0"
          >
            <template v-slot:item.action="{ item }">
              <v-btn v-if="uiAccept.accedit" icon depressed color="primary" @click="editAccount(item)">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn v-if="uiAccept.accdelete" icon depressed color="error" @click="deleteAccount(item)">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import accountService from "../service/UserAccountService";
import billService from "../service/BillService";
export default {
  data() {
    return {
      validAccount: false,
      accountDialog: false,
      account: {
        accountType: "STAFF",
        password: null,
        shortName: null,
        userAccountName: null,
        status: "ADD",
      },
      accountRule: [v => !!v || "Username is required"],
      accountShortRule: [v => !!v || "Profile Name is required"],
      accountPasswordRule: [v => !!v || "Account Password is required"],
      accountType: ["ADMIN",  "ACCOUNTANT","STAFF","MSSTAFF"],
      accountHeader: [
        { text: "Username", value: "userAccountName" },
        { text: "Profile Name", value: "shortName" },
        { text: "Branch", value: "branch.description" },
        { text: "Account Type", value: "accountType" },
        { text: "Action", value: "action" }
      ],
      accountList: [],
      branchList:[],
      uiAccept:{},
    };
  },
  mounted: function() {
    this.uiAccept = this.$store.state.uiAccept;
    this.getAccount();
    this.getBranchListMethod();
  },
  methods: {
    // account
    getBranchListMethod:function(){
billService
        .getBranch()
        .then((response) => {
          this.branchList.push(...response);
          this.branch = this.branchList[0];
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    getAccount: function() {
      accountService.getAccount().then(response => {
        this.accountList.splice(0, this.accountList.length);
        this.accountList.push(...response);
      });
    },
    addAccount: function() {
      if (this.$refs.accountForm.validate()) {
        this.account.branchId = this.branch.branchId;
        accountService
          .addAccount(this.account)
          .then(() => {
            this.$swal(
              "Successful",
              "Added New Account Successful!",
              "success"
            );
            this.getAccount();
            this.accountDialog = false;
            this.account = {
              accountType: "STAFF",
              password: null,
              shortName: null,
              userAccountName: null,
              status: "ADD"
            };
            this.$refs.accountForm.resetValidation();
          })
          .catch(err => {
            this.$swal("Failed", err.response.data.message, "error");
          });
      }
    },
    editAccount: function(account) {
      account.status = "UPDATE";
      this.account = Object.assign({}, account);
      this.accountDialog = true;
    },
    updateAccount: function() {
      if (this.$refs.accountForm.validate()) {
        this.account.branchId = this.branch.branchId;
        console.log(this.account);
        accountService
          .updateAccount(this.account)
          .then(() => {
            this.$swal("Updated Successful", "Account is Updated!", "success");
            this.getAccount();
            this.account = {
                accountType: "STAFF",
                password: null,
                shortName: null,
                userAccountName: null,
                status: "ADD"
            };
            this.accountDialog = false;
            this.$refs.accountForm.resetValidation();
          })
          .catch(err => {
            this.$swal("Failed", err.response.data.message, "error");
          });
      }
    },
    deleteAccount: function(account) {
      accountService
        .deleteAccount(account)
        .then(() => {
          this.$swal("Delete Successful", "Account is Deleted!", "success");
          this.getAccount();
          this.$refs.accountForm.reset();
          this.$refs.accountForm.resetValidation();
          this.account.status = "ADD";
          this.accountDialog = false;
        })
        .catch(err => {
          this.$swal(
            "Failed",
            "This data is used in somewhere Or " + err.response.data.message,
            "error"
          );
        });
    },
    cancelAccount: function() {
      this.account = {
        accountType: "STAFF",
        password: null,
        shortName: null,
        userAccountName: null,
        status: "ADD"
      };
      this.accountDialog = false;
    }
  }
};
</script>